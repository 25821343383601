if ($(window).width() < 769) {
	$(document).on("click", ".product-filter-title", function () {
		$(this).next().slideToggle();
	});
}

$(".toggle-product-grid").on("click", function (e) {
	e.preventDefault();
	let productCols = $(".product-col");
	let grid = $(this).data("grid");

	if (grid === 3) {
		$(productCols).addClass("col-lg-4");
	}

	if (grid === 4) {
		$(productCols).removeClass("col-lg-4");
	}
});

function filterToggle() {
	$(".filter-item").each(function (index, el) {
		let toggle = $(this).find(".current");
		let content = $(this).find(".dropdown-content");
		let siblings = $(".filter-item")
			.find(".dropdown-content")
			.not($(content));
		if ($(window).width() < 1025) {
			$(toggle).on("click", function () {
				$(content).slideToggle();
				$(siblings).slideUp();
			});
		}
	});
}

filterToggle();

window.filterToggle = filterToggle;
