import Swiper from "swiper";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import LoopSlider from "./LoopSlider";

let activeIndex = $(".nav-item.active").index();
let slideNavSlider = new Swiper(".slidenav .swiper", {
	modules: [Navigation],
	slidesPerView: "auto",
	spaceBetween: 12,
	initialSlide: activeIndex,
	navigation: {
		nextEl: ".slidenav .swiper-next",
		prevEl: ".slidenav .swiper-prev",
	},
});

let homeBannerSlider = new Swiper(".home-banner-slider .swiper", {
	modules: [Pagination, Autoplay],
	slidesPerView: 1,
	spaceBetween: 12,
	loop: true,
	autoplay: {
		delay: 4000,
	},
	pagination: {
		el: ".home-banner-slider .swiper-pagination",
		clickable: true,
	},
});

let projectDetailImages = new Swiper(".project-detail-images .swiper", {
	modules: [Navigation, Autoplay],
	slidesPerView: 1,
	spaceBetween: 12,
	loop: true,
	autoplay: {
		delay: 4000,
	},
	navigation: {
		nextEl: ".project-detail-images .swiper-next",
		prevEl: ".project-detail-images .swiper-prev",
	},
});

let projectHomeSlider = new Swiper(".project-home-slider .swiper", {
	modules: [Navigation],
	slidesPerView: 1,
	spaceBetween: 12,
	loop: true,
	navigation: {
		nextEl: ".project-home-slider .swiper-next",
		prevEl: ".project-home-slider .swiper-prev",
	},
});

const productSlider = new LoopSlider(".product-slider", {
	576: {
		spaceBetween: 16,
		slidesPerView: 2.5,
	},
	768: {
		spaceBetween: 16,
		slidesPerView: 3.5,
	},
	1024: {
		spaceBetween: 32,
		slidesPerView: 4,
	},
});

const projectSlider = new LoopSlider(".project-slider", {
	576: {
		spaceBetween: 16,
		slidesPerView: 1.75,
	},
	768: {
		spaceBetween: 16,
		slidesPerView: 2.5,
	},
	1024: {
		spaceBetween: 32,
		slidesPerView: 3,
	},
});

let careerPolicySlider = new Swiper(".career-policy-slider .swiper", {
	modules: [Navigation],
	slidesPerView: 1.5,
	spaceBetween: 16,
	loop: true,
	navigation: {
		nextEl: ".career-policy-slider .swiper-next",
		prevEl: ".career-policy-slider .swiper-prev",
	},
	breakpoints: {
		576: {
			slidesPerView: 2.5,
		},
		768: {
			slidesPerView: 3.5,
		},
		1024: {
			spaceBetween: 32,
			slidesPerView: 4,
		},
	},
});
