var $globalHeader = $(".global-header");

$(function () {
	let headerHeight = $globalHeader.outerHeight();
	$("body").css({
		"--header-height": headerHeight + "px",
	});
	window.headerHeight = headerHeight;
});

let headerPositionCss = $globalHeader.css("position");

if (headerPositionCss === "relative") {
	$globalHeader.sticky({
		top: 0,
		zIndex: 100,
	});
}

$(window).on("scroll", function () {
	var scrollTop = window.pageYOffset || $(document).scrollTop();
	$globalHeader.toggleClass("scrolling", scrollTop > 0);
});
$globalHeader.on("sticky-end", function () {
	$("#sticky-wrapper").css({
		height: headerHeight,
	});
});

const menuMapping = new MappingListener({
	selector: ".menu-wrapper",
	mobileWrapper: ".menu-mobile-body",
	mobileMethod: "appendTo",
	desktopWrapper: ".header-right-bottom",
	desktopMethod: "prependTo",
	breakpoint: 1025,
}).watch();

const utilMapping = new MappingListener({
	selector: ".util-wrapper",
	mobileWrapper: ".menu-mobile-body",
	mobileMethod: "appendTo",
	desktopWrapper: ".header-right-top",
	desktopMethod: "prependTo",
	breakpoint: 1025,
}).watch();

const searchMapping = new MappingListener({
	selector: ".search-wrapper",
	mobileWrapper: ".menu-toggle",
	mobileMethod: "insertBefore",
	desktopWrapper: ".header-right-top",
	desktopMethod: "appendTo",
	breakpoint: 1025,
}).watch();

$(".close-menu-mobile, .menu-mobile-backdrop").on("click", function () {
	$(".menu-mobile").removeClass("show");
	$("body").removeClass("overflow-hidden");
	$(".menu-mobile-backdrop").fadeOut();
});

$(".toggle-sub-menu").each(function (index, el) {
	$(el).on("click", function () {
		$(el).next().slideToggle();
	});
});

$(".toggle-mega").each(function (index, el) {
	$(el).on("click", function () {
		$(el).next().toggleClass("show");
	});
});

$(".toggle-mega-sub-menu").each(function (index, el) {
	$(el).on("click", function () {
		var targetMenu = $(el).parent().next();

		// Slide up all the other .mega-menu-lv2 elements
		$(".mega-menu-lv2").not(targetMenu).slideUp();
		$(".toggle-mega-sub-menu").not($(el)).removeClass("fa-rotate-180");

		// Toggle the clicked element
		targetMenu.slideToggle();
		$(el).toggleClass("fa-rotate-180");
	});
});

$(".close-mega").each(function (index, el) {
	$(el).on("click", function () {
		$(el).parent().removeClass("show");
	});
});

$(".menu-toggle").on("click", function () {
	$(".menu-mobile").addClass("show");
	$("body").addClass("overflow-hidden");
	$(".menu-mobile-backdrop").fadeIn();
});

// $(document).on("click", function () {
// 	$(".suggestsearch").remove();
// 	$(".searchinput").val("");
// });
