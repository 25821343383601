import { Fancybox } from "@fancyapps/ui";
import "./modules/Header";
import "./modules/Breadcrumb";
import "./modules/Slider";
import "./modules/Accordion";
import "./modules/Tab";
import "./modules/Product";
import "./modules/ProductDetail";
import "./modules/CountUp";
import "./modules/BackToTop";

var lazyLoadInstance = new LazyLoad({
	// Your custom settings go here
});

window.lazyLoadInstance = lazyLoadInstance;

Fancybox.bind("[data-fancybox]", {
	// Your custom options
});

$(function () {
	$(".footer-item h3").matchHeight();
	$(".change-link").on("change", function () {
		var url = $(this).val(); // Get the URL from the selected option
		if (url) {
			// If a URL was selected
			window.location = url; // Go to the URL
		}
	});
});
