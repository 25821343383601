import scrollToElement from "./ScrollElement";


jQuery(function(){
	$('.solution-tab-list').tabslet({
		container: $(".solution-content-list")
	});
	
	$('.solution-tab-list').on("_after", function (e) {
		scrollToElement("#solutionContentList", 1000, window.headerHeight)
	});

})