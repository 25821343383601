import Swiper from "swiper";
import { Navigation, Thumbs } from "swiper/modules";
import { Fancybox } from "@fancyapps/ui";

const productDetailSlider = () => {
	const productDetailThumbnailSlider = new Swiper(
		".product-detail-thumbnail .swiper",
		{
			spaceBetween: 20,
			slidesPerView: 2.5,
			observer: true,
			observeParents: true,
			slideToClickedSlide: true,
			watchSlidesVisibility: true,
			watchSlidesProgress: true,
			breakpoints: {
				768: {
					slidesPerView: 3.5,
				},
				1024: {
					slidesPerView: 4,
				},
			},
		}
	);
	const productDetailImageSlider = new Swiper(
		".product-detail-images .swiper",
		{
			modules: [Thumbs],
			observer: true,
			observeParents: true,
			thumbs: {
				swiper: productDetailThumbnailSlider,
			},
			on: {
				slideChange: function () {
					let activeIndex = this.activeIndex + 1;

					let nextSlide = $(
						`.product-detail-thumbnail .swiper-slide:nth-child(${
							activeIndex + 1
						})`
					);
					let prevSlide = $(
						`.product-detail-thumbnail .swiper-slide:nth-child(${
							activeIndex - 1
						})`
					);

					if (
						nextSlide &&
						!nextSlide.hasClass("swiper-slide-visible")
					) {
						this.thumbs.swiper.slideNext();
					} else if (
						prevSlide &&
						!prevSlide.hasClass("swiper-slide-visible")
					) {
						this.thumbs.swiper.slidePrev();
					}
				},
			},
		}
	);
};

productDetailSlider();

$(".btn-expand-figure").on("click", function () {
	$(this).find("em").toggleClass("fa-arrow-down fa-arrow-up");
	$(".product-img-zoom figure").toggleClass("is-expanded");
});

$(".btn-trigger-fullscreen").on("click", function () {
	$(".product-detail-images-wrap").toggleClass("is-fullscreen");
	$("body").toggleClass("overflow-hidden");
	let zoomableImage = document.querySelectorAll(
		".product-img-zoom figure.is-zoomable img"
	);
	zoomableImage.forEach((item) => {
		new Drift(item, {
			namespace: null,
			showWhitespaceAtEdges: !1,
			containInline: !0,
			sourceAttribute: "data-zoom",
			// paneContainer: item,
			zoomFactor: 1.5,
			inlinePane: !0,
			handleTouch: !0,
			injectBaseStyles: !0,
			hoverDelay: 0,
			touchDelay: 150,
		});
	});
});

$(".btn-close-fullscreen").on("click", function () {
	$(".product-detail-images-wrap").toggleClass("is-fullscreen");
	$("body").toggleClass("overflow-hidden");
});
