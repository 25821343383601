import Swiper from 'swiper';
import { Navigation } from 'swiper/modules';
class LoopSlider {
    constructor(sliderClass, breakpoints) {
        this.sliderClass = sliderClass;
        this.breakpoints = breakpoints;
        this.init();
    }

    init() {
        $(this.sliderClass).each((index, element) => {
            const $this = $(element);

            const sliderIndexClass = `${this.sliderClass.slice(1)}-${index}`;
            $this.addClass(sliderIndexClass);
            $this.find(".swiper-prev").addClass(`${sliderIndexClass}-prev`);
            $this.find(".swiper-next").addClass(`${sliderIndexClass}-next`);

            new Swiper(`.${sliderIndexClass} .swiper`, {
                slidesPerView: 1.5,
                spaceBetween: 16,
                watchSlidesVisibility: true,
				modules: [Navigation],
                navigation: {
                    prevEl: `.${sliderIndexClass}-prev`,
                    nextEl: `.${sliderIndexClass}-next`,
                },
                breakpoints: this.breakpoints,
            });
        });
    }
}

export default LoopSlider;
